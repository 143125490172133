export default [
  {
    path: 'Basicinfo',
    name: {list:['个人中心','账户信息','账户信息'],title:'账户信息'},
    component: () => import('@/view/peopleCenter/Basicinfo.vue')
  },
  {
    path: 'peomessage',
    name: {list:['个人中心','账户信息','基本信息'],title:'基本信息'},
    component: () => import('@/view/peopleCenter/peomessage.vue')
  },
  {
    path: 'address',
    name: {list:['个人中心','账户信息','地址管理'],title:'地址管理'},
    component: () => import('@/view/peopleCenter/address.vue')
  },
  {
    path: 'autManagement',
    name: {list:['个人中心','账户信息','我的认证'],title:'我的认证'},
    component: () => import('@/view/peopleCenter/autManagement.vue')
  },
  {
    path: 'personalAuth',
    name: {list:['个人中心','账户信息','我的认证'],title:'认证'},
    component: () => import('@/view/peopleCenter/personalAuth.vue')
  },
  {
    path: 'autManageDetail',
    name: {list:['个人中心','账户信息','我的认证'],title:'认证详情'},
    component: () => import('@/view/peopleCenter/autManageDetail.vue')
  },
  {
    path: 'changePhone',
    name: {list:['个人中心','账户信息','修改手机号']},
    component: () => import('@/view/peopleCenter/changePhone.vue')
  },
  {
    path: 'centerIndex',
    name: {title:'买家中心'},
    component: () => import('@/view/buyerCenter/index.vue')
  },
  {
    path: 'creditApply',
    name: {list:['个人中心','财务','授信申请']},
    component: () => import('@/view/buyerCenter/creditApply.vue')
  },{
    path: 'limitInfo',
    name: {list:['个人中心','财务','授额信息']},
    component: () => import('@/view/buyerCenter/limitInfo.vue')
  },
  {
    path: 'goodsCollect',
    name: {list:['个人中心','关注中心','商品收藏']},
    component: () => import('@/view/buyerCenter/myCollection.vue')
  },{
    path: 'shopCollect',
    name: {list:['个人中心','关注中心','店铺收藏']},
    component: () => import('@/view/buyerCenter/myCollection.vue')
  },
  {
    path: 'entpAuth',
    name: {list:['个人中心','账户信息','我的认证'],title:'企业认证'},
    component: () => import('@/view/peopleCenter/entpAuth.vue')
  },
  {
    path: 'entpAuthDetail',
    name: {list:['个人中心','账户信息','我的认证'],title:'企业认证详情'},
    component: () => import('@/view/peopleCenter/entpAuthDetail.vue')
  },
]
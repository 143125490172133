let Api = {
    
    //购物车
    "SHOPCART_FINDPRODUCTCOUNT": "/b2b/w/shopCart/findProductCount",//查询购物车商品数量
    "SHOPCART_FINDPRODUCTLIST": "/b2b/w/shopCart/findProductList",//查询购物车商品列表
    "SHOPCART_UPDATEPRODUCTSPEC": "/b2b/w/shopCart/updateProductSpec",//修改商品数量
    "SHOPCART_DELPRODUCT": "/b2b/w/shopCart/delProduct",//删除选中的商品
    "SHOPCART_CONFIRMORDER": "/b2b/w/shopCart/confirmOrder",//确认订单
    //买家中心订单
    "ORDER_STATECOUNT": "/b2b/w/buyerOrder/stateCount",//查询我的页面订单状态数量
    "ORDER_FINDLIST": "/b2b/w/buyerOrder/findBuyerList",//查询订单列表
    // "ORDER_FINDLIST": "/b2b/w/buyerOrder/findBuyerPageList",//分页查询订单列表
    "ORDER_DEL": "/b2b/w/buyerOrder/delOrder",//删除订单
    "ORDER_CANCEL": "/b2b/w/buyerOrder/cancelBuyerOrder",//取消订单
    "ORDER_CANCELREASON": "/b2b/w/order/cancel",//商家取消订单
    "ORDER_CONFIRM": "/b2b/w/buyerOrder/confirmDelivery",//确认收货
    "COMMCOLL_FINDPAGELIST": "/b2b/w/commColl/findPageList",//商品收藏记录 分页列表查询
    "COMMCOLL_DEL": "/b2b/w/commColl/del",//收藏记录 逻辑删除
    //授信信息
    "REALAUTH_FINDINFO": "/system/w/realPersonAuth/findInfoByUser",//买家实人认证
    "MEMB_GETINFO": "/system/w/memb/getMembInfo",//认证状态
    "APPAYAPPLY_CANLIST": "/b2b/w/apPayApply/apPayCanApplyList",//买家查询可申请账期支付商家列表
    "APPAYAPPLY_APPLYAPPAY": "/b2b/w/apPayApply/applyApPay",//买家查询可申请账期支付商家列表
    "APPAYAPPLY_FINDAPPLYLIST": "/b2b/w/apPayApply/findApPayApplyList",//买家查询申请账期支付记录
    "APPAYAPPLY_FINDBUYERMAGELIST": "/b2b/w/apPayMage/findBuyerApPayMageList",//买家查询账期授信列表
    //店铺收藏
    "COMMCOLLSHOP_FINDPAGELIST": "/b2b/w/CommCollShop/findPageList",//店铺收藏 分页列表查询
    "COMMCOLLSHOP_DEL": "/b2b/w/CommCollShop/del",//收藏记录 逻辑删除
    //新品推荐
    "N_SHOPHOME_FINDESPRODUCTPAGELIST": "/b2b/n/ShopHome/findEsProductPageList", //商品列表
    //优选基地
    "DIFAM_FINDPORTALENTP": "/difam/n/findPortalEntp", //优选基地分页列表
    "DIFAM_FINDENTPDETAIL": "/difam/n/findEntpDetail", //优选基地详情
    "DIFAM_PRODUCT_FINDLIST": "/difam/n/product/findList",//基地商品不分页列表
    "DIFAM_LINK_VIDEOLIST": "/difam/n/link/videoList",//优选基地摄像头设备列表
    "DIFAM_LINK_IOTLIST": "/difam/n/link/iotList",//优选基地物联网设备列表


}
module.exports = Api
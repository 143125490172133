export default [
    {
      path: 'InvoiceMent',
      name: {list:['个人中心','财务','发票管理'],title:'发票管理'},
      component: () => import('@/view/InvoiceMent/InvoiceMent.vue')
    },
    {
      path: 'mentdetail',
      name: {list:['个人中心','财务','发票管理'],title:'发票详情'},
      component: () => import('@/view/InvoiceMent/mentdetail.vue')
    },
    {
      path: 'outInvoice',
      name: {list:['个人中心','财务','发票管理'],title:'开票申请'},
      component: () => import('@/view/InvoiceMent/outInvoice.vue')
    },
    {
      path: 'InvoicTitle',
      name: {list:['个人中心','财务','发票管理'],title:'发票抬头管理'},
      component: () => import('@/view/InvoiceMent/InvoicTitle.vue')
    },
  
  ]
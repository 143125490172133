let Api = {
	"BUYERORDER_FINDBUYERPAGELIST": "/b2b/w/buyerOrder/findBuyerPageList", //分页查询订单列表
	"BUYERORDER_FINDBUYERORDERINFO": "/b2b/w/buyerOrder/findBuyerOrderInfo", //查询订单详细信息
	"BUYERORDER_STATECOUNT": "/b2b/w/buyerOrder/stateCount", //查询我的页面订单状态数量(角标)
	"BUYERORDER_CANCELBUYERORDER": "/b2b/w/buyerOrder/cancelBuyerOrder", //取消订单
	"SYSTEMTAG_FINDLISTBYTYPE": "/b2b/w/systemTag/findListByType", //取消订单原因列表
  "BUYERORDER_CONFIRMDELIVERY": "/b2b/w/buyerOrder/confirmDelivery", //确认收货
  "BUYERORDER_DELORDER": "/b2b/w/buyerOrder/delOrder", //删除订单
  "BUYERORDER_TRACKINGBYORDERID": "/b2b/w/order/trackingByOrderId", //物流跟踪根据订单id
	"BUYERORDER_FINDBUYEORDERREFUNDPAGELIS": "/b2b/w/buyerOrder/findBuyeOrderRefundPageList", //分页查询退款申请列表
  "BUYERORDER_FINDBUYEORDERREFUNDINFO": "/b2b/w/buyerOrder/findBuyeOrderRefundInfo", //查询退款申请详细信息 退款申请id
  "BUYERORDER_APPLREFUND": "/b2b/w/buyerOrder/applRefund", //申请退款
  "SHOPCART_CONFIRMORDER1": "/b2b/w/shopCart/confirmOrder", //再次购买
  "BUYERORDER_REVOKEORDERREFUNDAPPL": "/b2b/w/buyerOrder/revokeOrderRefundAppl", //取消退款服务单
	"BUYERORDER_FINDORDERREFUNDPAGELIST": "/b2b/w/buyerOrder/findOrderRefundPageList", //分页查询退款订单列表


  // 账期结算单相关接口
  "SETTLEBILL_FINDPAGE": "/b2b/w/buyer/settleBill/findPage", //账期结算单分页列表
  "SETTLEBILL_GETDETAIL": "/b2b/w/buyer/settleBill/getDetail", //账期结算单查询详细信息
  "SETTLEBILL_EXAMSETTLEINVO": "/b2b/w/buyer/settleBill/examSettleInvo", //验票
  "SETTLEBILL_SETTLEDISSENT": "/b2b/w/buyer/settleBill/settleDissent", //验票异议
  "SETTLEBILL_CANCELSETTLEBILL": "/b2b/w/buyer/settleBill/cancelSettleBill", //取消对账单
  "SETTLEBILL_INVOAPPLSAVE": "/b2b/w/buyer/settleBill/invoApplSave", //申请发票保存处理
  "SETTLEBILL_BUYERSETTLELISTNUM": "/b2b/w/buyer/settleBill/buyerSettleListNum", //查询结算单列表角标
  "SETTLEBILL_BUYERNOTSETTLEPAGE": "/b2b/w/buyer/settleBill/buyerNotSettlePage", //查询未出账单分页列表
  "SETTLEBILL_NOTSETTLEORDERLIST": "/b2b/w/buyer/settleBill/notSettleOrderList", //未出账期订单列表
  "SETTLEBILL_PRESETTLECHECK": "/b2b/w/buyer/settleBill/preSettleCheck", //未出账单提前结算验证处理
  "SETTLEBILL_PRESETTLESAVE": "/b2b/w/buyer/settleBill/preSettleSave", //未出账单提前提前结算保存处理
  "INVOINFO_FINDALLLISTBYMEMBID": "/b2b/w/InvoInfo/findAllListByMembId", //查询某个会员所有发票信息列表
  "INVOINFO_FINDINFO": "/b2b/w/InvoInfo/findInfo", //发票查询详细信息
  "INVOINFO_SAVEORUPDATE": "/b2b/w/InvoInfo/saveOrUpdate", //新增或修改发票
  "SETTLEBILL_RECOSETTLEBILLCHECK": "/b2b/w/buyer/settleBill/recoSettleBillCheck", //对账验证处理

  "SETTLEBILL_RECOSETTLEBILL": "/b2b/w/buyer/settleBill/recoSettleBill", //对账处理保存处理
  "SETTLEBILL_RECONDISSENT": "/b2b/w/buyer/settleBill/reconDissent", //对账异议
  "ORDER_TRACKING": "/b2b/w/order/tracking", //发票物流跟踪
  "SETTLEBILL_TOPAYDETAIL": "/b2b/w/buyer/settleBill/toPayDetail", //去支付页面详细信息
  "SETTLEBILL_INVODOWNLOADZIP": "/b2b/w/buyer/settleBill/invoDownloadZip", //下载发票

  


  


  
  
  
  
}
module.exports = Api
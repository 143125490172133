import { createRouter, createWebHistory } from 'vue-router';
import centerRoutes from './centerRoutes';
import mallRoutes from './mallRoutes';
import { getCookie } from '../assets/js/cookie'
import Center from '@/layout/center.vue';
// import Mall from '@/layout/mall.vue';

const router = createRouter({
  history: createWebHistory("/mall/"),
  routes: [
    {
      name: '商城',
      path: '/',
      component: Center,
      children: mallRoutes
    },
    {
      path: '/Pdfcomp',
      name: 'pdf预览',
      component: () => import('@/view/pdf/Pdfcomp.vue')
    },
    {
      name: '首页',
      path: '/',
      component: Center,
      children: [
        {
          path: '',
          name: "/",
          component: () => import('@/components/dealSlide/slide'),
          children: centerRoutes,
        },
      ],
    },
    { name: '买家中心首页',
      path: '/',
      component: Center,
      children: [
        {
          path: '',
          name: "/",
          component: () => import('@/components/buyerCenter/slide'),
          children: centerRoutes,
        },
      ],
    },
    {
      name: '登录',
      path: '/login',
      component: () => import('@/view/user/login'),
    },
    {
      name: '注册',
      path: '/register',
      component: () => import('@/view/user/register'),
    },
    {
      name: '买家注册协议',
      path: '/userAgreement',
      component: () => import('@/view/user/userAgreement'),
    },
    {path: '/chatIndex',name: '聊天',component: () => import('@/components/chat/chatIndex')},
    {
      name: '外链接品种',
      path: '/varietiesLink',
      component: () => import('@/view/varieties/layout/center.vue'),
      children: [
        {
          path: '',
          name: '外链品种列表',
          component: () => import('@/view/varieties/index.vue')
        },
        {
          path: 'details',
          name: '外链品种详情页',
          component: () => import('@/view/varieties/details.vue')
        },
      ],
    },
  ],
  
  scrollBehavior() {
    return { top: 0 };
  },
});
router.beforeEach((to, from, next) => {
  next()
});


// createRouteGuard(router);
console.log(router,'router')
export default router;



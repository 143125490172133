<template>
  <div>
    <div data-v-2f725251="">
      <div class="header_wrap">
        <div class="header">
          <div class="header_left">
            <span class="hello">您好，欢迎来到{{b2bWebInfo.name}}</span>
            <span
              class="register h1"
              v-if="!userInfoB2B.id"
              @click="jumper('/login')"
              >登录</span
            >
            <span class="register h1" v-if="userInfoB2B.id">{{
              userInfoB2B.loginName
            }}</span>
            <span
              class="register h1"
              v-if="!userInfoB2B.id"
              @click="jumper('/register')"
              >注册</span
            >
            <span class="register h1" v-if="userInfoB2B.id" @click="logout"
              >退出</span
            >
          </div>
          <div class="header_right">
            <ul>
              <li>
                <div class="li_item" @click="toHref('/business',false,'target')">我是卖家</div>
              </li>
              <li>
                <div class="li_item" @click="toHref('/centerIndex', true)">
                  买家工作台
                </div>
              </li>
              <li>
                <div class="li_item" @click="toHref('/purOrder', true)">我的订单</div>
              </li>
              <li>
                <el-dropdown placement="bottom-start">
                  <div class="li_item">账号管理</div>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click="toHref('/peomessage', true)">账户信息</el-dropdown-item>
                        <el-dropdown-item @click="toHref('/Basicinfo', true)">账户安全</el-dropdown-item>
                        <el-dropdown-item @click="toHref('/autManagement', true)">企业认证</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 搜索 -->
      <!-- 第一种样式 -->
      <div v-if="isShowSearch">
        <div class="xmfk_home_topbg">
          <div class="xmfk_home_top_search">
            <div class="xmfk_home_top_search_left">
              <!-- <a class="xmfk_logo_wrap">
                                <img src="/" alt="logo">
                            </a>
                            <div class="xmfk_logo_name">
                                <div class="logo_name">产业B2B交易中心</div>
                                <div class="logo_title">货源稳定 品质恒定的采购平台</div>
                            </div> -->
              <a class="xmfk_logo_wrap" :style="{'max-width':(400-titleWidth-10)+'px'}">
                <img
                  v-if="b2bWebInfo.logo"
                  :src="commenUrl + b2bWebInfo.logo"
                  alt="logo"
                  class="logo"
                   @load="getImg"
                />
              </a>
              <div class="xmfk_logo_name" :style='{width:titleWidth+"px"}'>
                  <div class="logo_name" :style='{width:titleWidth+"px"}'>{{b2bWebInfo.name}}</div>
                  <div class="logo_title" :style='{width:titleWidth+"px"}'>{{b2bWebInfo.subName}}</div>
              </div>
            </div>
            <div class="xmfk_home_top_search_right">
              <div class="xmfk_seach_wrap">
                <div class="xmfk_seach_box ld">
                  <div class="form">
                    <input
                      type="text"
                      @keyup.enter="searchFn(searchKeyWord)"
                      v-model="searchKeyWord"
                      class="text"
                      autocomplete="off"
                      placeholder="请输入你想要的产品信息"
                      style="color: rgb(153, 153, 153)"
                    />
                    <input
                      type="submit"
                      @click="searchFn(searchKeyWord)"
                      class="button"
                      value="搜索"
                    />
                  </div>
                  <div class="hot_search_wrap">
                    <div>
                      <a
                        href="javascript:void(0)"
                        v-for="(searchLi, searchIndex) in searchList"
                        :key="searchIndex"
                        @click="searchFn(searchLi.label)"
                        >{{ searchLi.label }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="xmfk_cart_wrap">
                <svg-icon class="icon" iconName="icon-gouwuche"></svg-icon>
                <a href="javascript:void(0)" @click="toHref('/shopCart')"
                  >我的进货单</a
                >
                <div class="cart_goods_num">{{ prodoucCount }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="nav_cat">
          <ul class="header">
            <li
              v-for="(navLi, navIndex) in navList"
              :key="navIndex"
              :class="{ active: navIndex == selectId }"
              @click="toColumn(navLi, navIndex)"
            >
              <a>{{ navLi.columnName }}</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- 第二种样式 -->
      <div class="nav_wraptwo" style="display: none">
        <div class="nav_wraptwo_menu">
          <a class="logo_wrap">
            <img src="../../../../assets/images/logo.jpeg" alt="logo" />
          </a>
          <ul class="nav_wraptwo_list">
            <li
              v-for="(navLi, navIndex) in navList"
              :key="navIndex"
              :class="{ active: navIndex == selectId }"
              @click="toColumn(navLi, navIndex)"
            >
              <a>{{ navLi.columnName }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      userInfoB2B: (state) => state.userInfoB2B,
      b2bWebInfo: (state)=> state.b2bWebInfo,
    }),
  },
  props:{
    isShowSearch:{
      type:Boolean,
      default:true
    }
  }
};
</script>
<script setup>
import "../../css/index.css";
import { ref, reactive, getCurrentInstance, onMounted, inject, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import operate from "../../js/operate.js";
import { useStore } from "vuex";
const { proxy } = getCurrentInstance();
const store = useStore();
const navList = ref([
  {
    columnName: "首页",
    columnProperty: "1",
    columnContent: '{"href":"/"}',
    target: "1",
  },
]);
const userInfoB2B = ref(
  proxy.getCookie("userInfoB2B")
    ? JSON.parse(proxy.getCookie("userInfoB2B")).user
    : { loginName: "" }
);
const router = useRouter();
const showEdit = inject("show");
const preview = true;
const prodoucCount = ref(0);
onMounted(() => {
  //   楼层数据处理
  if (userInfoB2B.value.id) {
    getProductCount();
  }
  getWebImageInfo();
});
const titleWidth= ref(0)
if(userInfoB2B.value.name){
  let fontLength = userInfoB2B.value.name.length>userInfoB2B.value.subName.length?userInfoB2B.value.name.length:userInfoB2B.value.subName.length
  let showLength = module.value == '01'?10:10
  let showWeight = module.value == '01'?200:200
  titleWidth.value =fontLength*showLength>showWeight?showWeight:fontLength*showLength
}
const logoWidth = ref('100%')
const getImg = e=>{
  console.log('获取logo')
  logoWidth.value = document.getElementsByClassName('logo')[0].width
}
watch(userInfoB2B,(e)=>{
  let fontLength = e.name.length>e.subName.length?e.name.length:e.subName.length
  let showLength = module.value == '01'?10:10
  let showWeight = module.value == '01'?200:200
  titleWidth.value = fontLength*showLength>showWeight?showWeight:fontLength*showLength
})
watch(logoWidth,(e)=>{
  titleWidth.value = 400-10-e
})
const infoData = ref({ logo: "" });
const getWebImageInfo = () => {
  proxy
    .$request({
      url: "N_SYSTEMCONFIG_GETWEBBASEPICCONFIG",
      method: "post",
    })
    .then((res) => {
      if (res.data.code == "000") {
        infoData.value.logo = res.data.result.mallLogo;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const getProductCount = (e) => {
  proxy
    .$request({
      url: "B2B_FINDPRODUCTCOUNT",
      method: "post", //可以不传method 默认是post请求，
      data: {
        isLoginType: "1",
      },
    })
    .then((res) => {
      if (res.data.code == "000") {
        prodoucCount.value = res.data.result;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
const toHref = (e, t, jump) => {
  if (!preview) return;
  if(jump=='target'){
    var localObj = window.location;
    proxy.tool.openWindow(localObj.protocol + "//" + localObj.host + e);
  }else{
    if (t && !proxy.userInfoB2B?.loginName) {
      router.push({
        path: "/login",
        query: {},
      });
    } else {
      router.push({
        path: e,
        query: {},
      });
    }
  }
  
};
const selectId = ref(0);
const toColumn = (e, id) => {
  selectId.value = id;
  toPage({
    data: JSON.parse(e.columnContent),
    type: e.columnProperty,
    target: e.target,
  });
};
// 跳转处理
const toPage = (e) => {
  let href = "";
  let str = "";
  if (e.type == "0") {
    // 无操作
  } else if (e.type == "1") {
    //
    href = e.data.href;
  } else {
    let operateLi = operate.find((l) => e.type == l.value);
    href = operateLi.href;
    e.inner = operateLi.inner;
    e.target = "1"
    if(operateLi.parameter&&operateLi.parameter.length>0){
      operateLi.parameter.forEach((element,index) => {
        let arr = element.value.split(',')
        let name =''
        if(arr.length>1){
          arr.forEach(m=>{
            if(!name){
              name = e.data[m]
            }
          })
        }else{
          name = e.data[element.value]
        }
        if(index==0){
          str += "?"+element.name+"=" + name
        }else{
          str += "&"+element.name+"=" + name
        }
      });
    }
  }
  openWindow(href, e.target, e.inner, str);
};
const openWindow = (href, target, inner, str) => {
  if (!href) return;
  if (target == "1") {
    // 当前页签打开
    router.push(href + str);
  } else {
    if (inner) {
      const url = router.resolve({
        path: href,
      });
      proxy.tool.openWindow(url.href + str);
    } else {
      proxy.tool.openWindow(href);
    }
  }
};
// 搜索处理
const searchKeyWord = ref("");
// 搜索历史
const searchList = ref([
  { label: "化橘红" },
  { label: "广藿香" },
  { label: "广陈皮" },
  { label: "广佛手" },
]);
onMounted(() => {
  init();
});
const searchFn = (searchKeyWord) => {
  if (!preview) return;
  if (searchKeyWord) {
    router.push({ path: "/goodsList", query: { searchKey: searchKeyWord } });
  } else {
    router.push({ path: "/goodsList" });
  }
};
// 用户登录或者退出操作
const emits = defineEmits(["operate", "save"]);
const jumper = (href) => {
  if (!preview) return;
  toPage({ data: { href: href }, type: "1", inner: true });
};
const init = () => {
  proxy
    .$request({
      url: "B2B_W_INDUSTRYCOLUMNMANAGE_FINDLIST",
      method: "post", //可以不传method 默认是post请求，
      data: {
        isShow: "0",
      },
    })
    .then((res) => {
      if (res.data.code == "000") {
        navList.value = navList.value.concat(res.data.result);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const logout = () => {
  //退出
  proxy
    .$request({
      method: "post",
      url: "SECURITY_W_LOGOUT",
      data: {},
    })
    .then((res) => {
      var data = res.data;
      if (data.code == "000") {
        proxy.setCookie("userInfoB2B", "");
        proxy.$store.commit("setUserInfoB2B", "");
        proxy.setCookie("membId", "");
        userInfoB2B.value = {}
      } else {
        proxy.$alert(data.msg || "系统错误...", "错误提示", {
          cofirmButtonText: "确定",
          type: "error",
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
</script>
<style lang="less" scoped>
.xmfk_logo_wrap {
  // margin-right: 120px;
}
.li_item:focus-visible{
  outline: none;
}
.li_item{
  height: 14px !important;
}
</style>
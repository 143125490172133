const digitsRE = /(\d{3})(?=\d)/g;
import DataDict from './dataDict.js'
import EditDict from './editDict.js'
//声明变量，用于存储输入框的值。
var temp_th = "";
function amount(th) {
    var regStrs = [
        ['^0(\\d+)$', '$1'], //禁止录入整数部分两位以上，但首位为0
        ['[^\\d\\.]+$', ''], //禁止录入任何非数字和点
        ['\\.(\\d?)\\.+', '.$1'], //禁止录入两个以上的点
        ['^(\\d+\\.\\d{2}).+', '$1'] //禁止录入小数点后两位以上
    ];
    //如果获取不到输入框的值，则会将输入框上次的值赋值给它。以此解决数字输入时，如果输入两个小数点，会被清空内容的情况。
    if (th.value == "") {
        if (temp_th.length == 1) {
            th.value = "";
        } else if (temp_th.length == 2) {
            th.value = "";
        } else {
            th.value = "";
        }
    } else {
        temp_th = th.value;
    }
    for (let i = 0; i < regStrs.length; i++) {
        var reg = new RegExp(regStrs[i][0]);
        th.value = th.value.replace(reg, regStrs[i][1]);
    }
}

//输入金额处理方法（保留小数点后两位，不足两位自动补全）
function formatMoney(th) {
    var v = th.value;
    if (v == '') {
        v = '';
    } else if (v == '0') {
        v = '0.00';
    } else if (v == '0.') {
        v = '0.00';
    } else if (/^0+\d+\.?\d*.*$/.test(v)) {
        v = v.replace(/^0+(\d+\.?\d*).*$/, '$1');
    } else if (/^0\.\d$/.test(v)) {
        v = v + '0';
    } else if (!/^\d+\.\d{2}$/.test(v)) {
        if (/^\d+\.\d{2}.+/.test(v)) {
            v = v.replace(/^(\d+\.\d{2}).*$/, '$1');
        } else if (/^\d+$/.test(v)) {
            v = v + '.00';
        } else if (/^\d+\.$/.test(v)) {
            v = v + '00';
        } else if (/^\d+\.\d$/.test(v)) {
            v = v + '0';
        } else if (/^[^\d]+\d+\.?\d*$/.test(v)) {
            v = v.replace(/^[^\d]+(\d+\.?\d*)$/, '$1');
        } else if (/\d+/.test(v)) {
            v = v.replace(/^[^\d]*(\d+\.?\d*).*$/, '$1');
            // ty = false;
        } else if (/^0+\d+\.?\d*$/.test(v)) {
            v = v.replace(/^0+(\d+\.?\d*)$/, '$1');
            // ty = false;
        } else {
            v = '';
        }
    }
    th.value = v;
    return th.value;
}

// /*cj*/
// //富文本模块最后提交时对img做处理
// function submitUMDataStr(UMDataStr){
//     let reg=new RegExp(commonUrl,'g');
//     UMDataStr=UMDataStr.replace(reg,"");
//     return UMDataStr;
// };
// //富文本数据回显
// function backShowDomAndUM(UMDataStr){
//     let reg=/src="/g;
//     let reg1=/src="https|data:image/g;
//     let reg2=/src="http|data:image/g;
//     if(UMDataStr){
//         if( !((UMDataStr.search(reg1) > -1) || (UMDataStr.search(reg2) > -1)) ){
//             UMDataStr = UMDataStr.replace(reg,'src="'+commonUrl);
//         }
//     }
//     return UMDataStr;
// };

function cloneObj(obj) {
    var result = {};
    for (var key in obj) {
        result[key] = obj[key];
    }
    return result;
}







function intMoney(th, isZero) {
    var reg_whole_number = /\D|^0/g;//正整数 
    if (isZero == '1') {
        th.value = isNaN(parseInt(th.value.replace(/[^0-9-]+/, ''))) ? '0' : parseInt(th.value.replace(/[^0-9-]+/, ''));
    } else {
        th.value = th.value.replace(reg_whole_number, '');
    }
}


function intMoney100(th) {
    // var reg_whole_number = /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/;//正整数 
    // if (reg_whole_number.test(th.value)) {

    // }
    // //th.value = th.value.replace(reg_whole_number,'');
    if (th.value > 100) {
        th.value = 100;
    }
}
// function intNum(th) {
//     // var reg_whole_number = /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/;//正整数 
//     // if (reg_whole_number.test(th.value)) {
//     // }
//     //th.value = th.value.replace(reg_whole_number,'');
// }

function int100(th) {
    if (th.value) {
        var reg_whole_number = /^0{1}$|^[1-9]{1,2}$|^[1-9]0{1,2}$/;//正整数 
        if (!reg_whole_number.test(th.value)) {
            th.value = "100";
        }
    } else {
        th.value = "";
    }
}

function getUrl() {
    var objs = {};
    var str = window.location.href;
    var num = str.indexOf("?");
    var newStr = str.substr(num + 1);
    var strArr = newStr.split("&");
    var name;
    var val;
    for (var i = 0; i < strArr.length; i++) {
        var arrary = strArr[i].split("=");
        name = arrary[0];
        val = arrary[1];
        objs[name] = val;
    }
    return objs;
}

function getUrlParm(str) {
    var objs = {};
    var name;
    var val;
    var num = str.indexOf("?");
    var newStr = str.substr(num + 1);
    if (str.indexOf("&") > 0) {//说明有多个参数
        var strArr = newStr.split("&");
        for (var i = 0; i < strArr.length; i++) {
            var arrary = strArr[i].split("=");
            name = arrary[0];
            val = arrary[1];
            objs[name] = val;
        }
    } else {//一个参数
        arrary = newStr.split("=");
        name = arrary[0];
        val = arrary[1];
        objs[name] = val;
    }


    return objs;
}

//金额的处理
function currency(value, currency, decimals) {
    value = parseFloat(value)
    if (!isFinite(value) || (!value && value !== 0)) return '￥0.00'
    currency = currency != null ? currency : '￥'
    decimals = decimals != null ? decimals : 2
    var stringified = Math.abs(value).toFixed(decimals)
    var _int = decimals
        ? stringified.slice(0, -1 - decimals)
        : stringified
    var i = _int.length % 3
    var head = i > 0
        ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
        : ''
    var _float = decimals
        ? stringified.slice(-1 - decimals)
        : ''
    var sign = value < 0 ? '-' : ''
    return sign + currency + head +
        _int.slice(i).replace(digitsRE, '$1,') +
        _float
}
//提示信息


// var getBank = {
//     getBankLabel: function (code) {
//         var label;
//         headBankCode.forEach(item => {
//             if(code == item.value) {
//                 label = item.label;
//             }
//         });
//         return label;
//     },
// }

const dataALL= Object.assign(DataDict,EditDict) 
// 获取数据字典类型值
function getDictType(eve) {
    var tt = dataALL[eve];
    if (tt) {
        return tt;
    } else {
        return {};
    }
}

//获取数据字典label
function getDictLabel(eve, val) {
    var tt = dataALL[eve];
    if (tt) {
        for (var i = 0; i < tt.length; i++) {
            if (tt[i].value == val) {
                return tt[i].label;
            }
        }
    } else {
        return {};
    }
}
// let DataBank = require('./yopBankCode')
// // 获取数据字典类型值
// function getDictType1(eve) {
// 	var tt = DataBank[eve];
// 	if (tt) {
// 		return tt;
// 	} else {
// 		return {};
// 	}
// }
//获取数据字典label
// function getDictLabel1(eve, val) {
// 	var tt = DataBank[eve];
// 	if (tt) {
// 		for (var i = 0; i < tt.length; i++) {
// 			if (tt[i].code == val) {
// 				return tt[i].name;
// 			}
// 		}
// 	} else {
// 		return {};
// 	}
// }

//金额计算--加法
function accAdd(...arg) {
    var sum = 0;
    var m;
    var arr = [];
    arg.forEach((item, index) => {
        try {
            arr[index] = item.toString().split(".")[1].length;
        } catch (e) { arr[index] = 0 }
    })
    m = Math.pow(10, Math.max(...arr));
    arg.map(item => {
        sum += item * m;
    });
    return sum / m;
}

//金额计算--减法
function accSub(num, ...arg) {
    var sum = 0,
        m,
        arr = [],
        sub = 0;
    arg.forEach((item, index) => {
        try {
            arr[index] = item.toString().split(".")[1].length;
        } catch (e) { arr[index] = 0 }
    })
    m = Math.pow(10, Math.max(...arr));
    arg.map(item => {
        sum += item * m;
    });
    sub = num * m - sum
    return sub / m;
}


//秒转时分秒
let formatTime = (s) => {
    var t;
    if (s > -1) {
        var hour = Math.floor(s / 3600);
        var min = Math.floor(s / 60) % 60;
        var sec = s % 60;
        if (hour < 10) {
            t = '0' + hour + "时";
        } else {
            t = hour + "时";
        }
        if (min < 10) {
            t += "0";
        }
        t += min + "分";
        if (sec < 10) {
            t += "0";
        }
        t += parseInt(sec) + "秒";
    }
    return t;
}
//毫秒转换为格式化日期
let formatDate = (time, format) => {
    if (time) {
        var t = new Date(time);
        var tf = function (i) {
            return (i < 10 ? '0' : '') + i
        };
        return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
            switch (a) {
                case 'yyyy':
                    return tf(t.getFullYear());
                case 'MM':
                    return tf(t.getMonth() + 1);
                case 'mm':
                    return tf(t.getMinutes());
                case 'dd':
                    return tf(t.getDate());
                case 'HH':
                    return tf(t.getHours());
                case 'ss':
                    return tf(t.getSeconds());
            }
        })
    } else {
        return "";
    }

};
let openWindow = (href, type) => {
  var newWin = window.open();
  newWin.location = href
};
export {
    formatMoney,
    amount,
    // getPageNav,
    cloneObj,
    intMoney,
    intMoney100,
    // intNum,
    int100,
    getUrl,
    getUrlParm,
    //WiseaIMUIFn,
    currency,
    // submitUMDataStr,
    // backShowDomAndUM,
    //confirmFun,
    // getBank,
    //hitTip,
    accAdd,
    accSub,
    getDictType,
    getDictLabel,
    // getDictType1,
    // getDictLabel1,
    formatTime,
    formatDate,
    openWindow
}
function getCookie(name){
    return localStorage.getItem(name)
        
}
function setCookie(name, value ,path) {
    localStorage.setItem(name,value)
    
};

//删除cookie
function delCookie(name) {
    localStorage.removeItem(name)
        
}

export {
    getCookie,
    setCookie,
    delCookie
}
import {createStore} from "vuex"

//创建store实例
const store=createStore({
    state(){
        return {
            userInfoB2B:{},
            paymentAmount:'',
            b2bWebInfo:{}
        }
    },
    mutations:{
        setUserInfoB2B: (state, data) => {
          console.log(data)
            state.userInfoB2B = data
        },
        setPaymentAmount: (state, data) => {
          console.log(data)
            state.paymentAmount = data
        },
        setb2bWebInfo: (state, data) => {
          console.log(data)
          state.b2bWebInfo = data
        },
    }
})
export default store;
let Api = {
	"B2B_COMMSALECATG_LIST": "/b2b/n/commSaleCatg/list", //B2B查询一级类目及子类目
    "B2B_FINISHPROTAL_SAVE": "/b2b/w/FinishProtal/saveOrUpdate", //B2B装修保存
    "B2B_FINISHPROTAL_FINDPAGE": "/b2b/w/FinishProtal/findPageList", //B2B装修分页查询
    "B2B_FINISHPROTAL_FINDINFO": "/b2b/w/FinishProtal/findInfo", //B2B装修详情
    "B2B_FINISHPROTAL_FINDINFODEL": "/b2b/w/FinishProtal/del", //B2B装修删除
    "B2B_FINISHPROTAL_FINDINFOCOPY": "/b2b/w/FinishProtal/copy", //B2B装修复制



    // 获取应用的首页模板

	"B2B_W_INDUSTRYCOLUMNMANAGE_FINDLIST": "/b2b/n/IndustryColumnManage/findList", //栏目查询所有
    "B2B_FINDDEFAULTINFO": "/b2b/n/finishProtal/findDefaultInfo", //B2B装修复制
    "B2B_FINDPRODUCTCOUNT": "/b2b/w/shopCart/findProductCount", //获取进货单数量
    
	"B2B_N_SHPROTAL_BYID": "/b2b/n/finishProtal/findById", //查询专题

    "PORTAL_W_INDUSTRYARTICLEMAGE_FINDPAGELIST": "/portal-api/n/industryArticleMage/findPageList", //分页查询列表
	"BTB_W_INDUSTRYARTICLEMAGE_FINDPAGELIST": "/b2b/n/industryArticleMage/findPageList", //分页查询列表
    
}
module.exports = Api
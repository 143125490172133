<template>
  <router-view></router-view>
</template>

<script>

import { getCurrentInstance} from "vue";
import { useStore } from "vuex";
export default {
  name: 'App',
  components: {
  },
  setup(){
    const { proxy } = getCurrentInstance();
    const store = useStore();
    // 动态更改favicon
		const	changeFavicon=(src)=>{
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = src;
    }
    //获取门户信息
    const getWebInfo=()=>{
      proxy.$request({
        url:"B2B_N_MANAGE_FINDDEFAULT",
        method: "post",
      }).then((res)=>{
        if(res.data.code=="000"){
          let result=res.data.result;
          document.title = result.browserName;
          changeFavicon(proxy.commenUrl+result.browserIcon);
          store.commit("setb2bWebInfo",result);
        }
      }).catch((err)=>{
        console.log(err)
      })
    }
    getWebInfo();
    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: 1400px; */
  font-size: 14px;
  width: 100%;
  height: 100%;


}
body {
  margin: 0 !important;
  padding: 0;
  background-color: #f7f8fa;
}
body,html{
  width: 100%;
  height: 100%;
}
</style>

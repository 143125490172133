export default [
  {
    path: 'purOrder',
    name: {list:['个人中心','账户信息','账户信息'],title:'账户信息'},
    component: () => import('@/view/order/purOrder.vue')
  },
  {
    path: 'orderDetail',
    name: {list:['个人中心','采购订单','订单详情'],title:'订单详情'},
    component: () => import('@/view/order/orderDetail.vue')
  },
  {
    path: 'refunds',
    name: {list:['个人中心','客户服务','退款售后'],title:'退款售后'},
    component: () => import('@/view/order/refunds.vue')
  },
  {
    path: 'applicationForRefund',
    name: {list:['个人中心','客户服务','申请退款'],title:'申请退款'},
    component: () => import('@/view/order/applicationForRefund.vue')
  },
  {
    path: 'refundsDetail',
    name: {list:['个人中心','客户服务','退款售后'],title:'退款售后详情'},
    component: () => import('@/view/order/refundsDetail.vue')
  },
  {
    path: 'zqorder',
    name: {list:['个人中心','财务','账期结算单'],title:'账期结算单'},
    component: () => import('@/view/order/zqorder.vue')
  },
  {
    path: 'zqdetail',
    name: {list:['个人中心','财务','账期结算单'],title:'账期详情'},
    component: () => import('@/view/order/zqdetail.vue')
  },
  {
    path: 'wcorder',
    name: {list:['个人中心','财务','未出账单'],title:'未出账单'},
    component: () => import('@/view/order/wcorder.vue')
  },
  {
    path: 'wcdetail',
    name: {list:['个人中心','财务','未出账单'],title:'未出账单详情'},
    component: () => import('@/view/order/wcdetail.vue')
  }
]
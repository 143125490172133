<template>
  <div class="homePage" :style="'height:' + height + 'px'">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import Header from "../view/shopDecoration/components/page/header.vue";
import Footer from "@/components/dealFooter/foot.vue";
export default {
  name: "homePage",
  components: { Header,Footer },
  setup() {
    const { proxy } = getCurrentInstance();
    const height = ref(document.documentElement.clientHeight);
    // 监听浏览器尺寸变化
    onMounted(() => {
      if(proxy.getCookie("userInfoB2B")){
        proxy.$store.commit('setUserInfoB2B',JSON.parse(proxy.getCookie("userInfoB2B")).user)
      }
      window.addEventListener("resize", () => {
        // 监听页面尺寸变化
        // console.log(document.documentElement.clientHeight);
        height.value = document.documentElement.clientHeight;
      });
    });
    return {
      height,
    };
  },
};
</script>
<style scoped lang="less">
.homePage {
  // overflow: hidden;
  .container {
    display: flex;
    border-top: 1px solid #f7f8fa;
  }
  .rightContainer {
    flex-grow: 1;
    width: calc(100% - 240px);
    background-color: #f7f8fa;
  }
  :deep(.auth-dialog){
    .auth-dialog-main{
      width: 100%;
      display: flex;
      justify-content: center;
      .auth-dialog-main-panel{
        width: 320px;
      }
    }
    .auth-other-opt{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .down-btn{
        font-size: 12px;
        a{
          color: #3e7fff;
        }
      }
    }
    .auth-dialog-footer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tips{
        width: 70%;
        font-size: 12px;
        color: #c7c7c7;
        text-align: left;
        font-family: PingFangSC-Regular;
      }
    }
  }
}
</style>

let EditDict = {
	"order_state_type_b2b": [{
		"id": "1061",
		"label": "待确认",
		"value": "0"
	}, {
		"id": "1062",
		"label": "待付款",
		"value": "1"
	}, {
		"id": "1063",
		"label": "待发货",
		"value": "2"
	}, {
		"id": "1064",
		"label": "待收货",
		"value": "3"
	}, {
		"id": "1065",
		"label": "已完成",
		"value": "4"
	}, {
		"id": "1066",
		"label": "已取消",
		"value": "5"
	}, {
		"id": "1068",
		"label": "待备货",
		"value": "7"
	}, {
		"id": "1069",
		"label": "待自提",
		"value": "8"
	}],
	"refund_appl_state_type_b2b": [{
		"id": "1081",
		"label": "待审核",
		"value": "0"
	}, {
		"id": "1083",
		"label": "已拒绝",
		"value": "2"
	}, {
		"id": "1084",
		"label": "已完成",
		"value": "3"
	}, {
		"id": "1085",
		"label": "已取消",
		"value": "4"
	}],
}
module.exports = EditDict
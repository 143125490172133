let Api = {
	"N_SHOPHOME_FINDESPRODUCTPAGELIST": "/b2b/n/ShopHome/findEsProductPageList", //商品列表
	"N_SHOPHOME_FINDESPRODUCTINFO": "/b2b/n/ShopHome/findEsProductInfo", //商品详情
	"W_SHOPCART_ADDPRODUCT": "/b2b/w/shopCart/addProduct", //加入购物车
	"W_PERSON_GET": "/b2b/w/person/get", //获取个人信息
	"N_SHOPHOME_BRANDMAGE_FINDALLLIST": "/b2b/n/ShopHome/brandMage/findAllList", //商品品牌
	"N_SHOPHOME_FINDSHOPINFO": "/b2b/n/ShopHome/findShopInfo", //查询店铺信息
	"N_SHOPHOME_SHOPCOMMCATG_LIST": "/b2b/n/ShopHome/shopCommCatg/list", //获取店铺商品销售分类列表
	"B2B_W_COMMCOLL_SAVE": "/b2b/w/commColl/save", //收藏记录 新增 商品
	"B2B_W_COMMCOLL_COLL": "/b2b/w/commColl/coll", //收藏记录 商品
	"B2B_W_COMMCOLL_DEL": "/b2b/w/commColl/del", //删除收藏记录 商品
	"B2B_W_COMMCOLLSHOP_COLL": "/b2b/w/CommCollShop/coll", //收藏记录 店铺
	"B2B_W_COMMCOLLSHOP_SAVEORUPDATE": "/b2b/w/CommCollShop/saveOrUpdate", //收藏新增 店铺
	"B2B_W_COMMCOLLSHOP_DEL": "/b2b/w/CommCollShop/del", //收藏删除 店铺
  
  
}
module.exports = Api
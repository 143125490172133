
import {request} from "./request"
import * as tool from "./util"
import {getCookie,setCookie,delCookie} from "./cookie"

//import mitt from "mitt"
export default {
    install(app){
        app.config.globalProperties.$request=request
        app.config.globalProperties.getCookie=getCookie
        app.config.globalProperties.setCookie=setCookie
        app.config.globalProperties.delCookie=delCookie
        app.config.globalProperties.tool=tool
        setCookie("menuList", null)
        var localObj = window.location;
        let commenUrl = process.env.VUE_APP_PUBLIC_PATH
        if(!commenUrl){
            commenUrl = localObj.protocol + "//" + localObj.host+process.env.VUE_APP_PUBLIC_NAME+'/sr/';
        }
        app.config.globalProperties.commenUrl = commenUrl
        let apiUrl = process.env.VUE_APP_BASE_API
        if(!apiUrl){
            apiUrl = localObj.protocol + "//" + localObj.host+process.env.VUE_APP_PUBLIC_NAME;
        }
        app.config.globalProperties.apiUrl = apiUrl
        app.config.globalProperties.UPLOADIMG = apiUrl+"/system/w/uploadImg"
        app.config.globalProperties.glob="global"

    }
}

<template>
	<div>
		<div class="footer" id="szncDoorFooter">
			<div class="footContent">

				<div class="footLi">
					<div class="footTitle">
						系统入口
					</div>
					<div class="footName pointer" v-for="item in enterList" :key="item.id">
						<div><a href="javascript:;" @click="openLink(item.linkAddress)">{{ item.linkName }}</a></div>
					</div>
					<!-- <div class="footName pointer">
						<div><a href="javascript:;" @click="jumper('/industry')">产业工作台</a></div>
						<div><a href="javascript:;" @click="jumper('/business')">企业工作台</a></div>
						<div><a href="javascript:;" @click="jumper('/detection')">抽样检测后台</a></div>
					</div> -->
				</div>
				<div class="footLi">
					<div class="footTitle">
						友情链接
					</div>
					<!-- <div class="footName pointer">
						<div><a href="http://hzhjh.com.cn/h-col-118.html" target="_blank">化橘红网站</a></div>
						<div><a href="http://www.nhc.gov.cn" target="_blank">国家卫生健康委员会</a></div>
						<div><a href="http://www.natcm.gov.cn" target="_blank">国家中医药管理局</a></div>
						<div><a href="https://www.nmpa.gov.cn" target="_blank">国家药品监督管理局</a></div>
						<div><a href="http://zygjjg.12388.gov.cn" target="_blank">中央国家机关举报网</a></div>
					</div> -->
					<div class="footName pointer" v-for="item in linkList" :key="item.id">
						<div><a href="javascript:;" @click="openLink(item.linkAddress)">{{ item.linkName }}</a></div>
					</div>
				</div>
				<!-- 相关链接 -->
				<div class="footLi">
					<div class="footTitle">
						关于我们
					</div>
					<ul>
						<!-- <li>
              <div class="footName">关于我们</div>
              <div class="footName">隐私政策</div>
              <div class="footName">诚聘英才</div>
              <div class="footName">意见反馈</div>
            </li>
            <li>
              <div class="footName">联系我们</div>
              <div class="footName">帮助中心</div>
              <div class="footName">合伙人招募</div>
              <div class="footName">平台投搜</div>
            </li> -->
						<li>
							<div class="footName pointer" v-for="item in aboutList" :key="item.id" @click="toHref(item.id)">
								{{ item.moduleName }}
							</div>
						</li>
					</ul>
				</div>
				<!-- 运营单位 -->
				<div class="footLi">
					<div class="footTitle">运营单位</div>
					<div class="footName">
						主办单位：{{ webInfoData.sponsor }}
					</div>
					<div class="footName">
						联系电话：{{ webInfoData.tel }}
					</div>
					<div class="footName">
						联系邮箱：{{ webInfoData.email }}
					</div>
					<div class="footName">
						<span>联系地址：</span>
						<span>{{ webInfoData.address }}</span>
					</div>
				</div>
				<div class="footLi" v-if="webInfoData.gzh || webInfoData.tradeImg">
					<div class="footTitle">服务方式</div>
					<div class="footName serviceWay">
						<div class="wayli" v-if="webInfoData.gzh">
							<div class="img">
								<img :src="commenUrl + webInfoData.gzh" alt="">
							</div>
							<div class="txt">官方公众号</div>
						</div>
						<div class="wayli" v-if="webInfoData.tradeImg">
							<div class="img">
								<img :src="commenUrl + webInfoData.tradeImg" alt="">
							</div>
							<div class="txt">官方正品交易通道</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footContent1">
				<div v-for="(qualificationsLi, qualificationsIndex) in qualificationsList" class="footClass">
					<img v-if="qualificationsLi.imgUrl" :src="commenUrl + qualificationsLi.imgUrl">
					<a :href="qualificationsLi.url" v-if='qualificationsLi.certificationType == "0"' target="_blank"
						style="margin-right:20px;">{{ qualificationsLi.name }}</a>
					<a @click="openPdf(qualificationsLi.fileUrl)" v-if='qualificationsLi.certificationType == "1"'
						target="_blank" style="margin-right:20px;">{{ qualificationsLi.name }}</a>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import { ref, getCurrentInstance, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
	components: {},
	name: "head",
	setup(props, cont) {
		const { proxy } = getCurrentInstance();
		const router = useRouter();
		const store = useStore();
		function jumper(path) {
			var localObj = window.location;
			var rootDomain = localObj.protocol + "//" + localObj.host;
			window.open(rootDomain + path, '_blank')
		}
		const webInfoData = computed(() => store.state.b2bWebInfo);
		//获取系统入口列表
		let enterList = ref([])
		const getEnterList = () => {
			proxy.$request({
				url: "B2B_N_SYSENTER_FINDLIST",
				method: "post",
				data: {
					"isShow": "0",
				},
			}).then((res) => {
				if (res.data.code == "000") {
					enterList.value = res.data.result;
				}
			}).catch((err) => {
				console.log(err)
			});
		}
		getEnterList();
		//获取链接列表
		let linkList = ref([])
		const getLink = () => {
			proxy.$request({
				url: "B2B_N_INDUSTRYFRIENDLYLINKMANAGE_FINDLIST",
				method: "post",
				data: {
					"isShow": "0",
				},
			}).then((res) => {
				if (res.data.code == "000") {
					linkList.value = res.data.result;
				}
			}).catch((err) => {
				console.log(err)
			});
		}
		getLink();
		//获取关于我们列表
		let aboutList = ref([]);
		const getAboutus = () => {
			proxy.$request({
				url: "B2B_N_MODULE_FINDLIST",
				method: "post",
				data: {
					"moduleCategory": "1",
				},
			}).then((res) => {
				if (res.data.code == "000") {
					aboutList.value = res.data.result.list;
				}
			}).catch((err) => {
				console.log(err)
			});
		}
		getAboutus();


		// 获取商务资质
		const qualificationsList = ref([])
		const getQualifications = () => {
			proxy.$request({
				url: "B2B_N_INDUSTRYCERTIFICATION_FINDALL",
				method: "post",
				data: {
					showType:'0'
				},
			}).then((res) => {
				if (res.data.code == "000") {
					qualificationsList.value = res.data.result;
				}
			}).catch((err) => {
				console.log(err)
			});
		}
		getQualifications();

		const toHref = (id) => {
			router.push({ path: '/aboutUs', query: { "id": id } })
		}
		const openLink = (url) => {
			window.open(url, '_blank');
		}
		const openPdf = (href) => {
			if (!href) return
			const url = router.resolve({
				path: 'Pdfcomp',
			});
			let obj = {
				page: proxy.commenUrl + href,
			};
			let params = btoa(JSON.stringify(obj))
			url.href += '?params=' + params
			proxy.tool.openWindow(url.href);
		}
		return {
			jumper,
			webInfoData,
			enterList,
			linkList,
			aboutList,
			toHref,
			openLink,
			qualificationsList,
			openPdf
		}
	}
}
</script>

<style lang="less" scoped>
.footer {
	width: 100%;
	min-height: 206px;
	padding-top: 37px;
	background-color: #050f0b;

	.footContent {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;

		.footLi {
			line-height: 24px;

			.footTitle {
				font-family: PingFangSC-Semibold;
				font-size: 15px;
				margin-bottom: 12px;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #ffffff;
			}

			.footName>div {
				line-height: 24px;
			}

			.footName>span {
				float: left;
				max-width: 300px;
			}

			.footName,
			a {
				font-family: PingFangSC-Regular;
				font-size: 14px;
				font-weight: normal;
				letter-spacing: 0px;
				color: #999999;
			}

			ul {
				display: flex;

				li {
					margin-right: 10px;
				}
			}
		}

		.pointer {
			cursor: pointer;
		}

		.serviceWay {
			display: flex;

			.wayli {
				margin-right: 14px;
				width: 88px;
				text-align: center;

				.img {
					width: 88px;
					height: 88px;
					margin-bottom: 8px;
					background-color: #ffffff;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.txt {
					font-size: 11px;
				}
			}
		}
	}

	.footContent1 {

		text-align: center;
		display: flex;
		width: 1200px;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 auto;
		padding: 0 0 6px;
		.footClass {
			display: flex;
			cursor: pointer;
			img {
				width: 16px;
				height: 16px;
				margin-right: 4px;
			}
		}

		a {
			color: #999999;
			font-size: 14px;
			text-decoration: none;
		}
	}
}
</style>
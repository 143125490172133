let Api = {
	"WEBWEIXIN_INDEX": "/system/n/webweixin/index",//获取微信登录二维码
	"WEBWEIXIN_SUCBACK": "/system/n/webweixin/sucBack",//微信登录成功页
	"MEMB_REGISTER": "/system/memb/registerBuy?ltype=buyWeb",//注册会员
	"MEMB_CHECKLOGINNAMEEXIST": "/system/memb/checkLoginNameExist",//验证用户名是否存在
	"MEMB_CHECKMOBILEEXIST": "/system/memb/checkMobileExist",//验证手机号是否存在
	"MOBILE_VALCODE_CREATE": "/security/mobile/valCode/create",//获取手机验证码
	"MOBILE_VALCODE_VALID": "/system/mobile/valCode/valid",//校验手机验证码
	"SECURITY_LOGIN": "/security/login?ltype=buyWeb",//登录
	"MOBILE_MOBILELOGIN": "/system/mobile/mobileLogin?ltype=buyWeb", //手机号登录
	"SYSTEM_MOBILE_VALCODE_SEND": "/system/mobile/valCode/send", //登录获取验证码
	"SECURITY_W_LOGOUT": "/system/w/logout",//登出
	"MEMB_GETMOBILE": "/system/memb/getMobile",//忘记密码获取手机号
	"MEMB_FORGOTPASSWORD": "/system/memb/forgotPassword",//重置密码

  "LOGIN": "/system/login?ltype=buyWeb&utype=master",//登录
	"MASTER_LOGIN": "/system/login?ltype=buyWeb&utype=master", //主账户登录
	"SUB_LOGIN": "/system/login?ltype=buyWeb&utype=sub", //子账户登录
	"MOBILE_MOBILELOGIN": "/system/mobile/mobileLogin?ltype=buyWeb", //手机号登录
	"SYSTEM_MOBILE_VALCODE_SEND": "/system/mobile/valCode/send", //登录获取验证码
	"SYSTEM_MEMB_REGISTER": "/system/memb/register", //注册
	"SYSTEM_MEMB_CHECKMOBILEEXIST": "/system/memb/checkMobileExist", //验证手机号是否已经存在
	"SYSTEM_MEMB_CHECKLOGINNAMEEXIST": "/system/memb/checkLoginNameExist", //验证用户名是否已经存在
	"SYSTEM_MOBILE_VALCODE_CREATE": "/system/mobile/valCode/create", //注册获取验证码
	"SYSTEM_N_FINDCOMMMAGETREE": "/system/n/findCommMageTree", //查询基础品类树
	"W_MEMB_FINDENTPMEMBINFO": "/system/w/entp/findEntpInfo", //查询会员基本信息


	"REALPERSONAUTH_FINDINFOBYUSER": "/system/w/realPersonAuth/findInfoByUser", //用户查询实人认证详情

	"REALPERSONAUTH_APPLYREALPERSONAUTH": "/system/w/realPersonAuth/applyRealPersonAuth", //申请实人认证
  "TENTCENTIM_USERSIGN": "/system/w/tencentIM/userSign", //获取用户加密串-即时通讯
  "SYSTEM_W_MEMB_GETMEMBINFO": "/system/w/memb/getMembInfo", //获取当前登录用户memb信息
  "SYSTEM_W_ENTPAUTH_APPLYENTPAUTH": "/system/w/entpAuth/applyEntpAuth", //用户申请企业认证
  "SYSTEM_W_ENTPAUTH_FINDINFOBYUSER": "/system/w/entpAuth/findInfoByUser", //用户查询企业认证详情
	"SYSTEM_MOBILE_CHECKCODE_CREATE": "/system/mobile/checkCode/create", //获取发送短信的图形验证码
  
	
}
module.exports = Api
let Api = { 
	"INVOAPPLY_FINDINVOAMOUNT": "/b2b/w/InvoApply/findInvoAmount", //查询开票金额
    "INVOAPPLY_FINDPAGELIST": "/b2b/w/InvoApply/findPageList", //分页查询列表买家商家
    "INVOINFO_FINDPAGELIST": "/b2b/w/InvoInfo/findPageList", //发票管理分页查询列表
    "INVOINFO_DEL": "/b2b/w/InvoInfo/del", //发票管理删除
    
    "INVOINFO_SETDEFAULT": "/b2b/w/InvoInfo/setDefault", //发票管理设置默认发票
    "INVOAPPLY_FINDUNINVOSELLERLIST": "/b2b/w/InvoApply/findUnInvoSellerList", //发票管理商家未开发票列表
    
    "INVOAPPLY_FINDUNINVOLIST": "/b2b/w/InvoApply/findUnInvoList", //查询未开发票订单列表
    "INVOAPPLY_SAVEORUPDATE": "/b2b/w/InvoApply/saveOrUpdate", //新增开票
    
    "INVOAPPLY_FINDINFO": "/b2b/w/InvoApply/findInfo", //查询详细信息
    
    "INVOAPPLY_INVODOWNLOADZIP": "/b2b/w/InvoApply/invoDownloadZip", //下载电子发票处理
} 
module.exports = Api
let Api = {
	"PORTALMAGE_PAGELIST": "/difam/w/entpPortalMage/findPageList", //门户模板配置列表
	"USER_UPDPWD": "/system/w/memb/user/updPwd", //修改登录密码(修改当前用户的密码)
	"MEMB_SENDFORREGTEL": "/system/w/memb/sendForRegTel", //手机号验证码发送(发送到当前用户注册的手机号)
	"MEMB_UPDMOBILEFIRST": "/system/w/memb/updMobileFirst", //修改注册手机号第一步(提交验证码)
	"MEMB_SENDNEWMOBILE": "/system/w/memb/sendNewMobile", //修改手机号第二步发送验证码到新手机号
	"MEMB_UPDMOBILESECOND": "/system/w/memb/updMobileSecond", //修改注册手机号第二步(保存操作)
	"ADDRESS_LIST": "/b2b/w/address/list", //获取收货地址列表
	"ADDRESS_GET": "/b2b/w/address/get", //查询收货地址
	"ADDRESS_DELETE": "/b2b/w/address/delete", //删除收货地址
	"ADDRESS_UPDATEFLAG": "/b2b/w/address/updateFlag", //设置默认收货地址
	"ADDRESS_SAVEORUPDATE": "/b2b/w/address/saveOrUpdate", //新增/修改收货地址
	"ADDRESS_AREA_FINDAREATREE": "/b2b/w/address/area/findAreaTree", //查询行政区划树
	"PERSON_GET": "/b2b/w/person/get", //查询个人信息
	"PERSON_UPDATE": "/b2b/w/person/update", //新增/修改个人信息
  "SYS_UPLOADIMG": "/system/w/uploadImg", //上传图片
  "PERSON_SETAPPAYPASSWORD": "/b2b/w/person/setApPayPassword", //设置支付密码
  
  
  
  
  
  
  
  

  
	
}
module.exports = Api